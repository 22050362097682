import React, { useRef, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import html2pdf from 'html2pdf.js';
import QRCode from 'qrcode.react';
import { Divider, Box, Button, Paper, Typography } from '@mui/material';
import PdfContent from './PdfContent';

import ImageOne from '../assets/background-building.png';
import ImageTwo from '../assets/HouseImage5.jpg';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';


const HomeOpenQR = ({ propertyDetails, agentDetails }) => {
  const qrRef = useRef();
  const [qrImage, setQrImage] = useState(null);

  // Set the base URL depending on the environment
  const baseURL = process.env.NODE_ENV === 'development'
    ? 'http://192.168.43.175:3000' // 'http://192.168.43.56:3000' 
    : 'https://propertee.org'; 

  // Update the QR data to include the full URL
  const qrData = `${baseURL}/check-in/${propertyDetails.homeOpenId}`;

  useEffect(() => {
    const canvas = qrRef.current.querySelector('canvas');
    if (canvas) {
      canvas.willReadFrequently = true;
      setQrImage(canvas.toDataURL('image/png'));
    }
  }, [propertyDetails]);

  const downloadImage = () => {
    const tempQRRef = document.createElement('div');

    createRoot(tempQRRef).render(
      <QRCode
        value={qrData}
        size={800}
        renderAs="canvas"
      />
    );

    setTimeout(() => {
      const canvas = tempQRRef.querySelector('canvas');
      const imageUrl = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      const fileName = `QR-${propertyDetails.listingId}-${propertyDetails.address.replace(/\s+/g, '_')}.png`;
      link.href = imageUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      tempQRRef.remove();
    }, 100);
  };

  const downloadPDF = () => {
    if (!qrImage) return;

    // Render your component into a container.
    const element = document.createElement('div');
    document.body.appendChild(element);
    const root = createRoot(element);
    root.render(<PdfContent propertyDetails={propertyDetails} qrImage={qrImage} propOne={ImageOne} propTwo={ImageTwo} agentDetails={agentDetails} />);

    // Give it a moment to render fully, especially if there are images or other resources.
    setTimeout(() => {
      // Use html2pdf to turn the rendered component into a PDF.
      html2pdf().from(element).set({
        margin: 1,
        filename: `QR-${propertyDetails.listingId}-${propertyDetails.address.replace(/\s+/g, '_')}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }).toPdf().get('pdf').then(function(pdf) {
        document.body.removeChild(element);  // Clean up the container element
      }).save();
    }, 500); // Adjust this timeout as needed
  };

  return (
    <Paper elevation={3} sx={{
      padding: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      maxWidth: 300,
    }}>
      <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
        Property Details
      </Typography>
      <Divider sx={{ my: 2, width: '90%' }} />
      <Box>
        <Typography variant="subtitle1" sx={{ display: 'flex', fontWeight: 'medium' }}>
          <LocationOnIcon sx={{ mr: 2, alignSelf: 'center' }} /> {propertyDetails.address}
        </Typography>
        <Divider sx={{ my: 2, width: '90%' }} />
        <Typography variant="subtitle1" sx={{ display: 'flex', fontWeight: 'medium' }}>
          <EventIcon sx={{ mr: 2, alignSelf: 'center' }} /> {propertyDetails.openHouseDate}
        </Typography>
      </Box>
      <Divider sx={{ my: 2, width: '90%' }} />
      <Box
        sx={{
          p: 1,
          //border: '2px solid var(--accent-color)', 
          //display: 'inline-block', 
        }}
        ref={qrRef}
      >
        <QRCode value={qrData} /> {/* Adjust size as needed */}
      </Box>
      <Divider sx={{ my: 2, width: '90%' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', width: '100%' }}>
        <Button variant="containedAccent" onClick={downloadImage} sx={{ width: '80%' }}>Download as Image</Button>
        <Button variant="outlined" onClick={downloadPDF} sx={{ width: '80%' }}>Download as PDF</Button>
      </Box>
    </Paper>
  );
};

export default HomeOpenQR;
