import {
  Container,
  Button,
  Dialog,
  CircularProgress,
  DialogActions,
  Divider,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Box,
  InputAdornment,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
} from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ImageSliderSimple from '../components/ImageSliderSimple';
import AuthContext from '../utilities/AuthContext';
import {
  getSinglePropertyById,
  getSingleHomeOpenById,
  checkInToHomeOpenAuthenticated,
  checkInToHomeOpenGuest,
  checkHomeOpenRegisterForUserById,
  submitOfferAuthenticated,
} from '../utilities/FirestoreFunctions';

import ProperteeCircularProgress from '../components/ProperteeCircular.js';

const CheckIn = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { homeOpenId } = useParams();
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [isGuestCheckIn, setIsGuestCheckIn] = useState(false);
  const [isGuestSubmitted, setIsGuestSubmitted] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!currentUser);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // Extended guestDetails to include new fields
  const [guestDetails, setGuestDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    offer: '',
    offerFormatted: '',
    financeType: '',
    termite: false,
    settlement: false,
    otherConditions: '',
  });

  const [offer, setOffer] = useState('');
  const [offerFormatted, setOfferFormatted] = useState('');
  const [additionalOfferDetails, setAdditionalOfferDetails] = useState({
    financeType: '',
    termite: false,
    settlement: false,
    otherConditions: '',
  });

  const [homeDetails, setHomeDetails] = useState({ address: '', price: 0, priceParameter: '', images: [] });
  const [homeOpenStatus, setHomeOpenStatus] = useState(''); 
  const [homeOpenTimes, setHomeOpenTimes] = useState({ startTime: null, endTime: null });

  const [previousCheckedIn, setPreviousCheckedIn] = useState(false);
  const [guestCheckInDocId, setGuestCheckInDocId] = useState('');

  const [loading, setLoading] = useState(false);
  const [propertyErrorMessage, setPropertyErrorMessage] = useState('');

  const [checkInClicked, setCheckInClicked] = useState(false);

  // Error states
  const [guestErrors, setGuestErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    offer: '',
  });

  const [offerError, setOfferError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const homeOpen = await getSingleHomeOpenById(homeOpenId);

        if (!homeOpen || !homeOpen.propertyId) {
          setPropertyErrorMessage('Invalid QR scanned, please try again with a valid property');
          return;
        }

        if (homeOpen && homeOpen.propertyId) {
          const property = await getSinglePropertyById(homeOpen.propertyId);

          if (!property) {
            setPropertyErrorMessage('Invalid QR scanned, please try again with a valid property');
            return;
          }

          const address = property.address.split(',')[0];
          const images = property.images.length > 2 ? property.images : [property.images[0]];

          setHomeDetails({
            address: address,
            price: property.price,
            priceParameter: property.priceParameter,
            images: images,
          });

          const startTime = homeOpen.start.toDate();
          const endTime = homeOpen.end.toDate();

          setHomeOpenTimes({ startTime, endTime });

          const now = new Date();

          if (now < startTime) {
            setHomeOpenStatus('not_started')
          } else {
            setHomeOpenStatus('in_progress');
          }

          if (currentUser) {
            const checkedIn = await checkHomeOpenRegisterForUserById(homeOpenId, currentUser.uid);
            setPreviousCheckedIn(checkedIn);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [homeOpenId, currentUser]);

  const handleCheckIn = () => {
    if (homeOpenStatus !== 'in_progress') {
      return;
    }

    if (currentUser) {
      const userDetails = {
        userId: currentUser.uid,
        name: currentUser.firstname + ' ' + currentUser.lastname,
        email: currentUser.email,
        phoneNumber: currentUser.phoneNumber,
        offer: '',
      }

      checkInToHomeOpenAuthenticated(homeOpenId, userDetails).then(() => {
        setIsCheckedIn(true);
      })
        .catch((error) => {
          console.error('Error checking in:', error);
          setCheckInClicked(false);
        });
    }
  };

  const handleCheckInLock = () => {
    setCheckInClicked(true);
    setTimeout(() => {
      setCheckInClicked(false);
    }, 1000);
  };

  const handlePhoneFocus = () => {
    let phoneInputValue = guestDetails.phoneNumber;
    if (phoneInputValue.startsWith('+61')) {
      phoneInputValue = phoneInputValue.slice(3).replace(/\s+/g, '');
    }
    setGuestDetails({ ...guestDetails, phoneNumber: phoneInputValue });
  };

  const handlePhoneBlur = () => {
    let phoneDigits = guestDetails.phoneNumber.replace(/\s+/g, '');

    if (phoneDigits.startsWith('0')) {
      phoneDigits = phoneDigits.slice(1);
    }

    const isValid = /^\d{9}$/.test(phoneDigits);

    if (isValid) {
      const formattedPhone = phoneDigits.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
      setGuestDetails({ ...guestDetails, phoneNumber: formattedPhone });
    } else {
      setGuestErrors({ ...guestErrors, phoneNumber: 'Invalid phone number. Please enter a valid Australian phone number with 9 digits.' });
    }
  };

  const validateName = (name) => {
    return name.trim().length > 0 ? '' : 'Name cannot be empty';
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email) ? '' : 'Invalid email format';
  };

  const validatePhoneNumber = (phoneNumber) => {
    let phoneDigits = phoneNumber.replace(/\s+/g, '');

    if (phoneDigits.startsWith('0')) {
      phoneDigits = phoneDigits.slice(1);
    }

    const isValid = /^\d{9}$/.test(phoneDigits);

    return isValid ? '' : 'Invalid phone number. Please enter a valid Australian phone number with 9 digits.';
  };

  const validateOffer = (offerValue) => {
    const unformattedOffer = offerValue.replace(/,/g, '');
    const offerAmount = parseFloat(unformattedOffer);
    if (isNaN(offerAmount) || offerAmount <= 0) {
      return 'Please enter a valid offer amount.';
    }

    if (homeDetails.priceParameter !== '*') {
      const price = parseFloat(homeDetails.price);
      if (offerAmount < price) {
        return `Offer must be at least ${formatPrice(price)}.`;
      }
    }

    return '';
  };

  const processPhoneNumber = (phoneNumber) => {
    let phoneDigits = phoneNumber.replace(/\s+/g, '');

    if (phoneDigits.startsWith('0')) {
      phoneDigits = phoneDigits.slice(1);
    }

    const isValid = /^\d{9}$/.test(phoneDigits);

    if (isValid) {
      return '+61' + phoneDigits;
    } else {
      return null;
    }
  };

  const formatTime = (date) => {
    return date.toLocaleString([], { dateStyle: 'short', timeStyle: 'short' });
  };

  const handleGuestCheckIn = async (e) => {
    e.preventDefault();

    if (homeOpenStatus !== 'in_progress') {
      return;
    }

    // Validate guestDetails
    const firstNameError = validateName(guestDetails.firstName);
    const lastNameError = validateName(guestDetails.lastName);
    const emailError = validateEmail(guestDetails.email);
    const phoneError = validatePhoneNumber(guestDetails.phoneNumber);
    let offerError = '';

    if (guestDetails.offer) {
      offerError = validateOffer(guestDetails.offer);
    }

    if (firstNameError || lastNameError || emailError || phoneError || offerError) {
      setGuestErrors({
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError,
        phoneNumber: phoneError,
        offer: offerError,
      });
    } else {
      setGuestErrors({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        offer: '',
      });

      try {
        const processedPhoneNumber = processPhoneNumber(guestDetails.phoneNumber);

        if (!processedPhoneNumber) {
          setGuestErrors({ ...guestErrors, phoneNumber: 'Invalid phone number. Please enter a valid Australian phone number with 9 digits.' });
          return;
        }

        // Include new fields in updatedGuestDetails
        const updatedGuestDetails = {
          ...guestDetails,
          name: `${guestDetails.firstName.trim()} ${guestDetails.lastName.trim()}`,
          phoneNumber: processedPhoneNumber,
        };

        const docId = await checkInToHomeOpenGuest(homeOpenId, updatedGuestDetails);
        setGuestCheckInDocId(docId); 
        setIsGuestSubmitted(true);
      } catch (error) {
        console.error('Error checking in:', error);
      }
    }
  };

  const handleBack = () => {
    setIsGuestCheckIn(false);
  };

  const handleGuestFormSubmit = (e) => {
    e.preventDefault();
    setIsCheckedIn(true);
  };

  const handleLogin = () => {
    if (guestCheckInDocId) {
      navigate('/login', { state: { from: location, guestCheckInDocId: guestCheckInDocId, homeOpenId: homeOpenId } });
    }
    else {
      navigate('/login', { state: { from: location } });
    }
  }

  const handleLogout = () => {
    setIsCheckedIn(false);
    setIsGuestCheckIn(false);
    setGuestDetails({ 
      firstName: '',
      lastName: '', 
      email: '', 
      phoneNumber: '', 
      offer: '', 
      offerFormatted: '', 
      financeType: '', 
      termite: false, 
      settlement: false, 
      otherConditions: '' 
    });
    setGuestErrors({ firstName: '', lastName: '', email: '', phoneNumber: '', offer: '' });
    setAdditionalOfferDetails({ financeType: '', termite: false, settlement: false, otherConditions: '' });
    setIsGuestSubmitted(false);
    setOffer('');
    setIsAuthenticated(false);
    logout();
  };

  const handleOfferSubmit = (e) => {
    e.preventDefault();
    const errorMsg = validateOffer(offer);
    if (errorMsg) {
      setOfferError(errorMsg);
    } else {
      setOfferError('');
      setOpenConfirmDialog(true);
    }
  };

  const confirmOfferSubmission = () => {
    // Submit with additional details
    submitOfferAuthenticated(homeOpenId, currentUser.uid, offer, additionalOfferDetails);
    setOffer('');
    setOfferFormatted('');
    setAdditionalOfferDetails({ financeType: '', termite: false, settlement: false, otherConditions: '' });
    setOpenConfirmDialog(false); 
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (isGuestCheckIn) {
      // Guest fields update
      if (type === 'checkbox') {
        setGuestDetails((prevDetails) => ({
          ...prevDetails,
          [name]: checked,
        }));
      } else if (name === 'offer') {
        const unformattedValue = value.replace(/,/g, '');
        if (!isNaN(unformattedValue) || unformattedValue === '') {
          setGuestDetails((prevDetails) => ({
            ...prevDetails,
            offer: unformattedValue,
            offerFormatted: unformattedValue === '' ? '' : Number(unformattedValue).toLocaleString(),
          }));
          setGuestErrors((prevErrors) => ({ ...prevErrors, offer: '' }));
        }
      } else {
        setGuestDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
        setGuestErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      }
    } else {
      // Authenticated user offer fields update
      if (name === 'offer') {
        const inputValue = value.replace(/,/g, '');
        if (!isNaN(inputValue) || inputValue === '') {
          setOffer(inputValue);
          setOfferError('');
          const formattedValue = inputValue === '' ? '' : Number(inputValue).toLocaleString();
          setOfferFormatted(formattedValue);
        }
      } else if (type === 'checkbox') {
        setAdditionalOfferDetails((prev) => ({ ...prev, [name]: checked }));
      } else {
        setAdditionalOfferDetails((prev) => ({ ...prev, [name]: value }));
      }
    }
  };

  const formatPrice = (price) => {
    return `$${Number(price).toLocaleString()}`;
  };

  if (loading) {
    return (
      <Container>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'var(--router-height)' }}>
          <ProperteeCircularProgress />
        </Box>
      </Container>
    );
  }
  else if (propertyErrorMessage) {
    return (
      <Container style={{ textAlign: 'center' }}>
        <Box my={4}>
          <Typography variant="h4" >
            Home Open Not Found
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="h6" >
            {propertyErrorMessage}
          </Typography>
        </Box>
      </Container>
    );
  }
  else if (homeOpenStatus === 'not_started') {
    return (
      <Container maxWidth="sm" sx={{ mt: 2, mb: 2, p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', minHeight: 'calc(99vh - 200px)', backdropFilter: 'blur(10px)' }}>
        <Typography variant="h6" gutterBottom textAlign="center">
          WELCOME TO{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {homeDetails.address}
          </Box>
        </Typography>
        <Divider sx={{ my: 2, width: '100%' }} />

        <ImageSliderSimple images={homeDetails.images} />

        <Divider sx={{ my: 2, width: '100%' }} />
        <Typography
          variant="body1"
          gutterBottom
          textAlign="center"
          sx={{ color: 'var(--primary)', mt: 2, mb: 1 }}
        >
          The home open hasn't started yet. Please come back at {formatTime(homeOpenTimes.startTime)}.
        </Typography>
      </Container>
    );
  }
  else if (homeOpenStatus === 'finished') {
    return (
      <Container maxWidth="sm" sx={{ mt: 2, mb: 2, p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', minHeight: 'calc(99vh - 200px)', backdropFilter: 'blur(10px)' }}>
        <Typography variant="h6" gutterBottom textAlign="center">
          WELCOME TO{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {homeDetails.address}
          </Box>
        </Typography>
        <Divider sx={{ my: 2, width: '100%' }} />

        <ImageSliderSimple images={homeDetails.images} />

        <Divider sx={{ my: 2, width: '100%' }} />
        <Typography
          variant="body1"
          gutterBottom
          textAlign="center"
          sx={{ color: 'var(--primary)', mt: 2, mb: 1 }}
        >
          The home open has finished. Thank you for your interest.
        </Typography>
      </Container>
    );
  }
  else {
    return (
      <Container maxWidth="sm" sx={{ mt: 2, mb: 2, p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', minHeight: 'calc(99vh - 200px)', backdropFilter: 'blur(10px)' }}>
        <Typography variant="h6" gutterBottom textAlign="center">
          WELCOME TO{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {homeDetails.address}
          </Box>
          <br />
          {homeDetails.priceParameter === '*' ?
            <Typography variant="h7" component="span">
              OPEN TO OFFERS
            </Typography>
            :
            <Typography variant="h7" component="span">
              OFFERS FROM{' '}
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {formatPrice(homeDetails.price)}
              </Box>
            </Typography>
          }
        </Typography>

        <Divider sx={{ my: 2, width: '100%' }} />

        <ImageSliderSimple images={homeDetails.images} />

        <Divider sx={{ my: 2, width: '100%' }} />

        {isGuestSubmitted ? (
          <>
            <Typography
              variant="body1"
              gutterBottom
              textAlign="center"
              sx={{ color: 'green', mt: 0, mb: 1 }}
            >
              Thank you for checking in as a guest.
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              textAlign="center"
              sx={{ color: 'var(--primary)', mt: 2, mb: 1 }}
            >
              Now that you've signed in, would you like to make it easier next time?
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleLogin()}
              sx={{ mt: 1, borderColor: 'var(--accent-color)', color: '#ffffff' }}>
              Sign Up
            </Button>
          </>
        ) : isCheckedIn ? (
          <>
            <Typography
              variant="body1"
              gutterBottom
              textAlign="center"
              sx={{ color: 'green', mt: 0, mb: 1 }}
            >
              Thank you for checking-in.
            </Typography>

            {/* Authenticated Offer Form */}
            <form onSubmit={handleOfferSubmit} noValidate autoComplete="off">
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  label="Offer Amount"
                  variant="outlined"
                  type="text"
                  value={offerFormatted}
                  onChange={handleInputChange}
                  name="offer"
                  InputProps={{
                    startAdornment: <Box component="span" sx={{ mr: 1 }}>$</Box>,
                  }}
                  error={!!offerError}
                  helperText={offerError}
                />

                {/* New fields for authenticated users */}
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Finance Options</FormLabel>
                  <RadioGroup
                    name="financeType"
                    value={additionalOfferDetails.financeType}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel value="cash" control={<Radio />} label="Cash Purchase" />
                    <FormControlLabel value="finance" control={<Radio />} label="Subject to Finance" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Conditions</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={additionalOfferDetails.termite}
                        onChange={handleInputChange}
                        name="termite"
                      />
                    }
                    label="Termite / Structural Assessment"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={additionalOfferDetails.settlement}
                        onChange={handleInputChange}
                        name="settlement"
                      />
                    }
                    label="Subject to Settlement"
                  />
                </FormControl>

                <TextField
                  label="Other Conditions"
                  name="otherConditions"
                  value={additionalOfferDetails.otherConditions}
                  onChange={handleInputChange}
                  margin="dense"
                  multiline
                  rows={2}
                />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ mt: 1, mb: 0, backgroundColor: 'var(--accent-color)', color: '#ffffff' }}
                >
                  Submit Offer
                </Button>
              </Box>
            </form>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleLogout}
              sx={{ mt: 1, mb: 1, borderColor: 'var(--accent-color)', color: 'var(--accent-color)' }}
            >
              Log Out
            </Button>

            <Dialog
              open={openConfirmDialog}
              onClose={() => setOpenConfirmDialog(false)}
              aria-labelledby="confirm-dialog-title"
              aria-describedby="confirm-dialog-description"
            >
              <DialogTitle id="confirm-dialog-title">{"Confirm Your Offer"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                  Are you sure you want to submit an offer of{" "}
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {formatPrice(offer)}
                  </Box>?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                  Cancel
                </Button>
                <Button onClick={confirmOfferSubmission} color="primary" autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : isGuestCheckIn ? (
          <Box component="form" onSubmit={handleGuestFormSubmit} noValidate>
            <Typography variant="h6" align="center" sx={{ my: 1, fontWeight: 'bold' }}>Guest Check-in</Typography>
            <FormGroup>
              <TextField
                label="First Name"
                name="firstName"
                value={guestDetails.firstName}
                onChange={handleInputChange}
                margin="dense"
                required
                error={!!guestErrors.firstName}
                helperText={guestErrors.firstName}
              />

              <TextField
                label="Last Name"
                name="lastName"
                value={guestDetails.lastName}
                onChange={handleInputChange}
                margin="dense"
                required
                error={!!guestErrors.lastName}
                helperText={guestErrors.lastName}
              />

              <TextField
                label="Email"
                name="email"
                value={guestDetails.email}
                onChange={handleInputChange}
                margin="dense"
                required
                error={!!guestErrors.email}
                helperText={guestErrors.email}
              />

              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={guestDetails.phoneNumber}
                onChange={handleInputChange}
                onFocus={handlePhoneFocus}
                onBlur={handlePhoneBlur}
                margin="dense"
                required
                InputProps={{
                  startAdornment: <InputAdornment position="start">+61</InputAdornment>,
                }}
                error={!!guestErrors.phoneNumber}
                helperText={guestErrors.phoneNumber}
              />

              <TextField
                label="Create an Offer (optional)"
                name="offer"
                value={guestDetails.offerFormatted || ''}
                onChange={handleInputChange}
                margin="dense"
                type="text"
                InputProps={{
                  startAdornment: <Box component="span" sx={{ mr: 1 }}>$</Box>,
                }}
                error={!!guestErrors.offer}
                helperText={guestErrors.offer}
              />

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Finance Options</FormLabel>
                <RadioGroup
                  name="financeType"
                  value={guestDetails.financeType}
                  onChange={handleInputChange}
                >
                  <FormControlLabel value="cash" control={<Radio />} label="Cash Purchase" />
                  <FormControlLabel value="finance" control={<Radio />} label="Subject to Finance" />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Conditions</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={guestDetails.termite}
                      onChange={handleInputChange}
                      name="termite"
                    />
                  }
                  label="Termite / Structural Assessment"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={guestDetails.settlement}
                      onChange={handleInputChange}
                      name="settlement"
                    />
                  }
                  label="Subject to Settlement"
                />
              </FormControl>

              <TextField
                label="Other Conditions"
                name="otherConditions"
                value={guestDetails.otherConditions}
                onChange={handleInputChange}
                margin="dense"
                multiline
                rows={2}
              />

              <Button
                variant="containedAccent"
                fullWidth
                type="submit"
                onClick={(event) => { handleGuestCheckIn(event); handleCheckInLock(); }}
                disabled={checkInClicked}
                sx={{ my: 1 }}
              >
                {checkInClicked ? <CircularProgress size={24} color="inherit" /> : "Check-in"}
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleBack}
                sx={{ my: 1 }}
              >
                Back
              </Button>
            </FormGroup>
          </Box>
        ) : (isAuthenticated ? (
          <>
            {previousCheckedIn ? (
              <>
                <Typography
                  variant="body1"
                  gutterBottom
                  textAlign="center"
                  sx={{ color: 'green', mt: 0, mb: 2 }}
                >
                  You've previously checked in for this Home Open.
                </Typography>
                <Typography
                  variant="h5"
                  gutterBottom
                  textAlign="center"
                  sx={{ color: 'green', mt: 0, mb: 1 }}
                >
                  Enjoy the viewing!
                </Typography>
              </>
            ) : (
              <Button
                variant="containedAccent"
                fullWidth
                onClick={(event) => { handleCheckIn(event); handleCheckInLock(); }}
                disabled={checkInClicked}
              >
                {checkInClicked ? <CircularProgress size={24} color="inherit" /> : "Check-in"}
              </Button>
            )}
          </>
        ) : (
          <>
            <Button variant="containedAccent" fullWidth onClick={() => handleLogin()} sx={{ my: 1 }}>
              Log In & Check-In
            </Button>
            <Button variant="containedAccent" fullWidth onClick={() => setIsGuestCheckIn(true)} sx={{ my: 1 }}>
              Check-In
            </Button>
            <Button variant="outlined" fullWidth onClick={() => handleLogin()} sx={{ my: 1 }}>
              Sign Up & Check-In
            </Button>
          </>
        )
        )}
      </Container>
    );
  }
};

export default CheckIn;
